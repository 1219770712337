@import 'Styles/Kulturama/includes';

/**
*
* SideBarLinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SideBarLinkList.Base.module.scss';

.SideBarLinkList {
    $root: &;

    &__List {
        margin-top: 10px;
    }

    &__Item {
        font-family: $font-base;
        padding: 18px 32px 52px 18px;
        background-color: palette(black, 5);
        border-top: 4px solid palette(pink);
        margin-bottom: 24px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            border: 32px solid white;
            border-left-width: 0;
            border-bottom-width: 0;
            border-top-color: palette(black, 5);
        }

        svg {
            position: absolute;
            bottom: 18px;
            left: 18px;
            stroke: palette(pink);
        }

        &--External {
            padding: 18px 32px 18px 18px;
            border: none;

            svg {
                top: 19px;
                stroke: transparent;
                fill: palette(pink);
            }
        }
    }

    &__Title {
        font-size: 1.8rem;
        color: palette(pink);
        font-weight: $medium;
        line-height: 1.5;

        #{$root}__Item--External & {
            font-size: 1.6rem;
            padding-left: 36px;
            color: palette(black, 78);
        }
    }

    &__Text {
        margin-top: 10px;
        font-size: 1.6rem;
        color: palette(black, 78);
    }

    &__Tag {
        color: palette(pink);
    }
}
