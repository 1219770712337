@import 'Styles/Dansskolanentre/includes';

/**
*
* Grid
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Grid.Base.module';

.Grid {
    $root: &;

    margin: 40px 0;

    @include media(md) {
        margin: 60px 0;
    }

    &__Title {
        margin-bottom: 24px;
        font-size: 3rem;
        font-weight: $bold;

        @include media(sm) {
            font-size: 4.8rem;
        }

        @include media(md) {
            margin-bottom: 32px;
            font-size: 5.8rem;
        }

        #{$root}--CardArticleSubject & {
            margin-bottom: 32px;
            font-size: 3rem;

            @include media(sm) {
                font-size: 3.2rem;
            }

            @include media(md) {
                font-size: 3.2rem;
            }
        }
    }

    &__Text {
        font-size: 2rem;
    }

    &__Grid {
        margin-left: -16px;

        #{$root}--CardArticleSubject &,
        #{$root}--Fourcol & {
            margin-left: -12px;

            @include media(sm) {
                margin-left: -16px;
            }
        }
    }

    &__Item {
        width: calc(100% - 16px);
        margin-left: 16px;
        margin-bottom: 16px;

        @include media(sm) {
            width: calc(50% - 16px);

            #{$root}--Half & {
                width: calc(50% - 16px);
            }

            #{$root}--Full & {
                width: calc(100% - 16px);
            }
        }

        #{$root}--ArchiveList &,
        #{$root}--CardNews &,
        #{$root}--Third & {
            @include media(lg) {
                width: calc(#{percentage(1/3)} - 16px);
            }
        }

        #{$root}--CardArticleSubject &,
        #{$root}--Fourcol & {
            width: calc(100% - 12px);
            margin-left: 12px;
            margin-bottom: 12px;

            @include media(xs) {
                width: calc(50% - 12px);
            }

            @include media(sm) {
                margin-left: 16px;
                margin-bottom: 16px;
                width: calc(#{percentage(1/3)} - 16px);
            }

            @include media(lg) {
                width: calc(25% - 16px);
            }
        }
    }

    &__Button {
        margin-top: 40px;
        text-align: center;
    }

    &__Link {
        @include u-button(solid large green);
    }
}
