@import 'Styles/Base/includes';

/**
*
* Grid
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Grid {

    &__Container {
        @include u-wrap(site);
    }

    &__Text {
        max-width: map-get($maxwidths, content);
        margin: auto;
        text-align: center;
    }

    &__Grid {
        display: flex;
        flex-wrap: wrap;
    }

    &__Item {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    &__Link {

        :global(.PageNews) & {
            display: none;
        }
    }
}
