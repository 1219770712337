@import 'Styles/Kulturama/includes';

/**
*
* ButtonLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ButtonLink {
    $root: &;

    @include u-button();

    display: inline-block;

    &--Light {
        @include u-button(white large, false);
    }

    &--Pink {
        @include u-button(pink solid, false);
    }

    &--Purple {
        @include u-button(purple solid large, false);
    }

    &--Large {
        @include u-button(solid large, false);
    }

    &--Arrow {
        //@include u-button(arrow, false);
    }

    &__Text {
        position: relative;
        color: currentColor;
        font-size: 1.4rem;
        font-weight: $bold;

        #{$root}__Link--Overflow & {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        #{$root}--Uppercase & {
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }
}
