@import 'Styles/Kulturama/includes';

@import 'CardArticleHalf.Base.module.scss';

.CardArticleHalf {
    $root: &;

    display: flex;
    flex: 1 0 auto;
    color: white;

    &--NoImage {

    }

    &__Link {
        display: none;
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 50px 20px;
        overflow: hidden;
        text-align: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media(sm) {
            padding: 60px;
            min-height: 300px;

        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background: black;
            opacity: .6;
        }

        #{$root}--NoImage & {
            background: palette(pink);

            &::after {
                display: none;
            }
        }
    }

    &__Content {
        position: relative;
        max-width: 740px;
        padding: 0 20px;
        font-size: 1.4rem;
        z-index: 1;

        @include media(sm) {
            font-size: 1.8rem;
        }
    }

    &__Tag {
        display: none;
    }

    &__Title {
        margin-bottom: 10px;
        font-size: 2.4rem;
    }

    &__ButtonWrapper {
        margin: 35px 0 0;
        position: relative;
    }

    &__Button {
        #{$root}:hover & {
            //@extend .ButtonLink:hover;
        }
    }
}
