@import 'Styles/Base/includes';

/**
*
* SideBarLinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SideBarLinkList {
    $root: &;

    &__Item {
        position: relative;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            #{$root}__Title {
                text-decoration: underline;
            }

            @media(hover: none) {
                text-decoration: none;
            }
        }
    }

    &__Href {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
