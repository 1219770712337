@import 'Styles/Medborgarskolan/includes';

/**
*
* GlobalSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'GlobalSearch.Base.module.scss';

.GlobalSearch {
    $root: &;
    background: $blue-20;

    &__Container {
        @include u-wrap(article);

        padding-top: 40px;
        padding-bottom: 56px;

        @include media(sm) {
            padding-top: 20px;
        }

        @include media(md) {
            padding-top: 72px;
        }
    }

    &__Header {
        color: $blue;

        @include media(xs) {
            display: block;
            margin-bottom: 32px;
        }
    }

    &__Form {
        background: white;
        border-radius: 100px;
        max-width: 618px;
    }

    &__Title {
        margin: 0;
        font-size: 1.4rem;
        font-weight: $normal;
        line-height: 1.54;
    }

    &__List {
        margin: 15px 0 56px;

        @include media(xs) {
            margin-left: 0;
            margin-right: 0;
        }

        @include media(sm) {
            margin: 19px 0 80px;
        }

        #{$root}__NoResult + & {
            @include media(md) {
                margin-top: 60px;
            }
        }
    }

    &__Button {
        @include u-button(primary);

        align-self: center;
    }

    &__NoResult {
        border-radius: 8px;
        background: white;
    }

    &__NoResultBorder {
        display: none;
    }

    &__NoResultText {
        em {
            font-weight: $bold;
        }
    }

    &__ErrorMessage {
        border-radius: 4px;
    }
}
