@import 'Styles/Dansskolanentre/includes';

/**
*
* HeroPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'HeroPage.Base.module';

.HeroPage {
    $root: &;

    clip-path: url(#hero-mobile-clip-path);

    @include media(sm) {
        clip-path: url(#hero-clip-path);
    }

    &__Background {
        background-color: black;

        &::after {
            background: black;
        }
    }

    &__Container {
        padding-top: 80px;
        padding-bottom: 100px;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;

        @include media(md) {
            min-height: 440px;
            padding-top: 120px;
            padding-bottom: 160px;
        }

        @include media(lg) {
            min-height: 570px;
        }
    }

    &__TextContainer {
        max-width: map-get($maxwidths, content);
    }

    &__Title {
        font-size: 4rem;
        font-weight: $bold;
        line-height: 1.1;
        color: white;
        text-align: left;
        hyphens: manual;
        text-shadow: 0 1px 20px rgba(black, .3);

        @include media(md) {
            font-size: 6.2rem;
        }
    }

    &__Text {
        margin: 10px auto 0;
        text-align: left;
        font-size: 1.8rem;
        font-weight: $medium;
        color: white;
        text-shadow: 0 1px 4px rgba(black, .2);

        @include media(md) {
            margin-top: 24px;
            font-size: 2rem;
        }
    }
}
