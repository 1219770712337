@import 'Styles/Pahlmans/includes';

.ButtonLink {
    $root: &;

    @include u-button();

    display: inline-block;

    &--Large {
        @include u-button(solid large, false);
    }
}