@import 'Styles/Medborgarskolan/includes';

/**
*
* Hero
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Hero.Base.module.scss';

.Hero {
    $root: &;

    &::before {
        background: black;
        opacity: .5;
    }

    &--NoImage {
        &::before {
            display: none;
        }
    }

    &--HasVideo {
        border-bottom: none;
    }

    &__Background {
        #{$root}--ShowVideo & {
            opacity: 0;
        }
    }

    &__Container {
        display: flex;
        flex-direction: column;
        padding-top: 60px;
        padding-bottom: 32px;

        @include media(md) {
            padding-top: 80px;
            padding-bottom: 40px;
            min-height: 350px;
        }

        #{$root}--HasVideo & {
            padding-top: 80px;
            min-height: 400px;

            @include media(md) {
                padding-top: 150px;
                min-height: 500px;
            }
        }

        #{$root}--PageHome & {
            padding-top: 80px;
            text-align: center;

            @include media(md) {
                padding-top: 13%;
                padding-bottom: 13%;
            }
        }

        #{$root}--PageCity &,
        #{$root}--PageContact &,
        #{$root}--PageOffer &,
        #{$root}--PageSubject & {
            padding-top: 80px;

            @include media(md) {
                padding-top: 100px;
            }
        }
    }

    &__TextContainer {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;

        #{$root}--PageHome & {
            @include u-wrap(title, false);

            width: 100%;
        }
    }

    &__BreakoutBreadcrumbs {
        @include u-wrap(site);

        position: relative;
        display: block;
        padding-top: 12px;
        color: white;
        z-index: 1;
    }

    &__Breadcrumbs {
        display: none;
    }

    &__Title {
        font-size: 4.4rem;
        font-weight: $bold;
        line-height: 1.1;
        color: white;

        @include media(md) {
            font-size: 6.4rem;
        }

        #{$root}--PageHome & {
            font-size: 4.2rem;
            text-shadow: 2px 2px 10px rgba(black, 0.3);

            @include media(md) {
                font-size: 5.6rem;
            }
        }

        #{$root}--PageCity &,
        #{$root}--PageContact &,
        #{$root}--PageOffer &,
        #{$root}--PageSubject & {
            max-width: map-get($maxwidths, content);
            margin-bottom: 5px;
            font-size: 3.2rem;
            line-height: 1.3;

            @include media(md) {
                margin-bottom: 0;
                font-size: 5.6rem;
            }
        }
    }

    &__Label {
        display: none;
    }

    &__Text {
        max-width: map-get($maxwidths, content);
        margin-top: 5px;
        color: white;
        font-size: 1.6rem;
        font-weight: $medium;

        @include media(md) {
            font-size: 2rem;
        }

        #{$root}--PageContact &,
        #{$root}--PageSubject &,
        #{$root}--PageOffer &,
        #{$root}--PageArchive & {
            display: block;
        }
    }

    &__Button {
        margin-top: auto;
        padding-top: 32px;
        flex: 0 0 auto;

        @include media(md) {
            padding-top: 40px;
            padding-bottom: 10px;
        }
    }

    &__Search {
        margin-top: 20px;

        @include media(md) {
            margin-top: 40px;
        }

        #{$root}--PageHome & {
            @include u-wrap(title, false);

            width: 100%;
            margin-top: 60px;

            @include media(md) {
                margin-top: 60px;
            }
        }
    }
}
