@import 'Styles/Pahlmans/includes';

@import 'CardArticleHalf.Base.module.scss';

.CardArticleHalf {
    $root: &;

    position: relative;

    &__Link {
        display: none;
    }

    &__Container {
        background: white;
        padding: 0px;

        @include media(sm) {
            display: flex;
            flex-flow: row-reverse wrap;
        }
    }

    &__Image {
        margin: -1px;

        @include media(sm) {
            width: 50%;
            min-height: 100%;
        }

        #{$root}--NoImage & {
            display: none;
        }
    }

    &__Content {
        padding: 40px 20px;
        max-width: 700px;
        margin: auto;
        text-align: center;

        @include media(sm) {
            width: 50%;
        }

        @include media(md) {
            padding: 45px 60px;
        }

        #{$root}--NoImage & {
           width: 100%;
        }
    }

    &__ContentInner {
        width: 100%; // IE
    }

    &__Tag {
        display: none;
    }

    &__Title {
        margin-bottom: 10px;
        color: black;
        text-align: center;
        font-size: 2.8rem;
        line-height: 1.2;
    }

    &__Text {
        max-width: 700px;
        text-align: center;
        font-size: 1.6rem;
        color: palette(black, 68);
    }

    &__Button {
        margin-top: 20px;
    }
}
