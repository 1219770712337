@import 'Styles/Base/includes';

/**
*
* Breadcrumbs
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Breadcrumbs {
    $root: &;

    display: flex;
    flex-wrap: wrap;

    &__Item {
        white-space: nowrap;
    }

    &__Link {

        &:hover {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }
    }

    &__Separator {

        #{$root}__Item:last-child & {
            display: none;
        }

    }
}
