@import 'Styles/Medborgarskolan/includes';

/**
*
* Breadcrumbs
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Breadcrumbs.Base.module.scss';

.Breadcrumbs {
    $root: &;

    @include media(md) {
        margin-bottom: 10px;

        &--Article {
            margin-bottom: 20px;
        }
    }

    &__Item {
        line-height: 16px;
    }

    &__Link,
    &__Page {
        display: inline-block;
        padding: 4px 0;
        color: white;
        font-size: 1.2rem;
        font-weight: $normal;

        #{$root}--Article & {
            color: $grey-70;
        }
    }

    &__Separator {
        display: inline-block;
        position: relative;
        top: 4px;
        margin: -4px 2px;

        &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;

            #{$root}:not(#{$root}--Article) & {
                background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/chevron--white.svg');
            }

            #{$root}--Article & {
                background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/chevron--grey-70.svg');
            }
        }

        svg {
            display: none;
        }
    }
}
