@import 'Styles/Medborgarskolan/includes';

/**
*
* SideBarLinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SideBarLinkList.Base.module.scss';

.SideBarLinkList {
    $root: &;

    margin-top: 6px;
    margin-bottom: 17px;

    @include media(sm) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &:first-child {
        @include media(sm) {
            margin-top: 10px;
        }
    }

    &:last-child {
        @include media(sm) {
            margin-bottom: 10px;
        }
    }

    &__Item {
        padding: 16px 24px 16px 45px;
        margin-bottom: 16px;
        border-radius: 8px;
        background-color: $blue-20;
        border-left: 8px solid $blue;

        // #{$root}--News & {
        //     border: 1px solid $blue;
        //     background-color: $blue-20;
        // }

        svg {
            display: none;
        }

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 24px;
            height: 24px;
            top: 16px;
            left: 12px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &--Local {
            &::before {
                background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--blue.svg');
            }
        }

        &--External {
            &::before {
                width: 24px;
                height: 24px;
                background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/external--blue.svg');
            }
        }
    }

    &__Title {
        font-size: 1.8rem;
        color: $blue;
        font-weight: $medium;
    }

    &__Text {
        display: none;
    }
}