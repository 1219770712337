@import 'Styles/Tillskararakademin/includes';

/**
*
* Breadcrumbs
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Breadcrumbs.Base.module.scss';

.Breadcrumbs {
    $root: &;

    align-items: center;
    justify-content: center;

    @include media(md) {
        margin-bottom: 10px;
    }

    &--Article {
        justify-content: flex-start;
        min-height: 53px;

        @include media(md) {
            min-height: 60px;
            margin-bottom: 20px;
            padding-top: 18px;
            border-bottom: solid 1px palette(black, 10);
        }
    }

    :global(.PageNews) & {
        @include media(md) {
            border-bottom: none;
            margin-bottom: 30px;
        }
    }

    &__Link {
        color: palette(white);
        font-size: 1.2rem;

        #{$root}--Article & {
            color: $text-color;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 0.9px;
        }

        @include media(md) {
            font-size: 1.4rem;

            #{$root}--Article & {
                font-size: 1.2rem;
            }

        }
    }

    &__Page {
        color: palette(white);
        font-size: 1.2rem;

        #{$root}--Article & {
            color: $text-color;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 0.9px;
        }

        @include media(md) {
            font-size: 1.4rem;

            #{$root}--Article & {
                font-size: 1.2rem;
            }

        }
    }

    &__Separator {
        padding: 0 8px;

        &:before {
            position: relative;
            top: 2px;
            content: '/';
            display: inline-block;
            color: palette(green);
            font-weight: $bold;
        }

        #{$root}--Article &:before {
            display: none;
        }

        svg {
            display: none;
        }

        #{$root}--Article & svg {
            display: inline-block;
            position: relative;
            top: 1px;
            width: 6px;
            height: 10px;

            * {
                fill: palette(black, 16);
            }
        }
    }
}
