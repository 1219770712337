@import 'Styles/Base/includes';

/**
*
* Hero
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Hero {
    $root: &;

    position: relative;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        opacity: .3;
        z-index: 1;
    }

    &__Background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        opacity: 1;
        overflow: hidden;
        transition: opacity .5s 1s ease-in-out;

        #{$root}--Video & {
            opacity: 0;
        }
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__BreakoutBreadcrumbs {
        display: none;
    }

    &__Container {
        @include u-wrap(site);
        
        position: relative;
        min-height: 300px;
        z-index: 1;
    }

    &__Label {
        margin-bottom: 15px;
        font-size: 1.4rem;
        text-transform: uppercase;
        letter-spacing: 2px;

        span {
            display: block;

            @include media(sm) {
                display: inline-block;
            }
        }
    }

    &__Text {
        display: none;
    }
}
