@import 'Styles/Medborgarskolan/includes';

@import 'CardArticleHalf.Base.module.scss';

.CardArticleHalf {
    $root: &;

    display: flex;
    flex: 1 0 auto;
    color: white;

    &--NoImage {
        color: black;
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        min-height: 471px;
        padding: 24px 16px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;

        @include media(sm) {
            padding: 40px 32px;
            min-height: 412px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background: linear-gradient(
                    0deg,
                    $blue-100 21.97%,
                    rgba(10, 8, 40, 0) 89.93%
                ),
                linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2) 0%,
                    rgba(0, 0, 0, 0.2) 100%
                );
        }

        #{$root}--NoImage & {
            &::after {
                display: none;
            }
        }
    }

    &__Content {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        z-index: 1;

        @include media(sm) {
            margin-top: 40px;
            flex-direction: row;
        }
    }

    &__ContentInner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;

        @include media(sm) {
            max-width: 466px;
        }
    }

    &__Tag {
        margin: 20px 0;
        font-size: 12px;
        font-weight: $bold;
        text-transform: uppercase;
    }

    &__Title {
        margin-bottom: 20px;
        font-size: 3rem;

        @include media(sm) {
            font-size: 3.8rem;
        }
    }

    &__ButtonWrapper {
        margin-top: 20px;

        @include media(sm) {
            display: flex;
            align-items: flex-end;
            margin: 0 0 0 auto;
        }
    }

    &__Button {
        @include u-button(primary);

        span {
            width: auto;
        }

        &::after {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: -4px 0 -4px 8px;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--white.svg')
                no-repeat center center;
            background-size: contain;
            transition: background 0.3s ease-in-out;

            @include media(sm) {
                margin-left: 0;
                width: 24px;
                height: 24px;
            }
        }

        @include media(sm) {
            padding: 14px;

            span {
                display: none;
            }
        }

        #{$root}:hover & {
            border-color: $link-hover-color;
            background-color: $link-hover-color;
        }
    }
}
