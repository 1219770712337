@import 'Styles/Dansskolanentre/includes';

@import 'CardArticleHalf.Base.module.scss';

.CardArticleHalf {
    $root: &;

    border-radius: 8px;
    background-color: $red-5;
    overflow: hidden;

    @include media(sm) {
        border-radius: 12px;
    }

    &__Link {
        display: none;
    }

    &__Container {
        @include media(sm) {
            display: flex;
            flex-flow: row-reverse wrap;
        }
    }

    &__Image {
        @include media(sm) {
            width: 50%;
            min-height: 100%;
        }

        #{$root}--NoImage & {
            display: none;
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
        padding: 24px 16px;

        @include media(sm) {
            width: 50%;
            padding: 32px 24px
        }

        @include media(md) {
            padding: 40px;
        }

        #{$root}--NoImage & {
           width: 100%;
        }

        p {
            color: $text-color;
            font-weight: $light;
            line-height: 1.52;

            @include media(sm) {
                font-size: 1.8rem;
            }
        }
    }

    &__ContentInner {
        width: 100%; // IE
    }

    &__Tag {
        display: none;
    }

    &__Title {
        font-size: 2.4rem;
        line-height: 1.37;
        margin-bottom: 16px;

        @include media(sm) {
            font-size: 2.6rem;
        }
    }

    &__ButtonWrapper {
        margin-top: auto;
    }

    #{$root} &__Button {
        height: auto;
        min-width: 0;
        margin-top: 20px;
        margin-bottom: 4px;
        padding: 0;
        color: $red-60;
        line-height: 1.5;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        white-space: nowrap;
        -webkit-appearance: none;

        &:hover {
            text-decoration: underline;
        }

        &::before {
            display: none;
        }

        &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin: -6px 0 -6px 9px;
            background: url('#{$basepath}img/bg-icons/dansskolanentre/arrow--red-60.svg') no-repeat center center;
            background-size: contain;
        }
    }
}
