@import 'Styles/Tillskararakademin/includes';

/**
*
* SideBarLinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SideBarLinkList.Base.module.scss';

.SideBarLinkList {
    $root: &;

    &__List {
        margin-top: 10px;
    }

    &__Item {
        padding: 18px 18px 54px;
        border-left: 4px solid palette(green);
        margin-bottom: 24px;

        svg {
            position: absolute;
            bottom: 18px;
            left: 18px;
            stroke: black;
            width: 18px;
            height: 18px;
        }

        &--External {
            padding: 18px;

            svg {
                width: 22px;
                height: 22px;
                top: 18px;
                fill: palette(green);
                stroke: transparent;
            }
        }
    }

    &__Title {
        font-family: $font-heading;
        font-size: 2rem;
        font-weight: $medium;
        line-height: 1.5;

        #{$root}__Item--External & {
            font-family: $font-base;
            padding-left: 34px;
            font-size: 1.6rem;
            font-weight: $normal;
            color: palette(black, 76);
        }
    }

    &__Text {
        margin-top: 10px;
        font-size: 1.6rem;
        color: palette(black, 76);
    }

    &__Tag {
        color: palette(green);
    }
}
