@import 'Styles/Tillskararakademin/includes';

@import 'CardArticleHalf.Base.module.scss';

.CardArticleHalf {
    $root: &;

    border: 1px solid palette(black, 16);

    &__Link {
        display: none;
    }

    &__Container{
        background-color: white;

        @include media(sm) {
            display: flex;
            flex-flow: row-reverse wrap;
        }
    }

    &__Image {
        margin: -1px;

        @include media(sm) {
            width: 50%;
            min-height: 100%;
        }

        #{$root}--NoImage & {
            display: none;
        }
    }

    &__Content {
        padding: 40px 20px;

        p {
            color: palette(black, 23);
        }

        @include media(sm) {
            width: 50%;
        }

        @include media(md) {
            padding: 45px 60px;
        }

        #{$root}--NoImage & {
           width: 100%;
        }
    }

    &__ContentInner {
        width: 100%; // IE
    }

    &__Tag {
        display: none;
    }

    &__Title {
        font-size: 3.2rem;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    &__Button {
        margin-top: 20px;

        span:not(:global(.sr-only)) {
            font-size: 1.6rem;
            padding-right: 10px;
        }

        &::after {
            width: 16px;
            height: 16px;
        }
    }
}
