@import 'Styles/Base/includes';

.CardArticleHalf {
    $root: &;

    position: relative;
    width: 100%;

    &__Link {
        position: absolute;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z();
    }

    &__Title {
        color: inherit;
    }
}
