@import 'Styles/Dansskolanentre/includes';

/**
*
* SideBarLinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SideBarLinkList.Base.module.scss';

.SideBarLinkList {
    $root: &;

    &__List {
        margin-top: 10px;
    }

    &__Item {
        padding: 18px 18px 54px;
        background-color: $red-5;
        border-radius: 8px;
        margin-bottom: 24px;

        @include media(sm) {
            border-radius: 12px;
        }

        &--Local {
            padding: 18px 18px 75px;

            svg {
                position: absolute;
                width: 24px;
                height: 24px;
                z-index: 1;
                transition: stroke 0.3s ease-in-out;
            }
          
        }

        &--External {
            padding: 27px;

            svg {
                position: absolute;
                z-index: 1;
                fill: white;
                transition: stroke 0.3s ease-in-out;
            }
        }
    }

    &__Icon {
        position: absolute;
        bottom: 18px;
        left: 18px;
        padding: 15px;
        width: 44px;
        height: 44px;
        background-color: red;
        border-radius: 100%;
        background: $gradient-color;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            inset: 0;
            background-color: $primary-color;
            opacity: 0;
            transition: all 0.3s ease-in-out;

            #{$root}__Item:hover & {
                opacity: 1;
            }
        }
    }

    &__Title {
        font-size: 2rem;
        font-weight: $medium;
        line-height: 1.5;

        #{$root}__Item--External & {
            padding-left: 50px;
            font-size: 1.6rem;
            font-weight: $medium;
            color: $red-60;
        }
    }

    &__Text {
        margin-top: 10px;
        font-size: 1.6rem;
        color: $text-color;
        font-weight: $light;
    }

    &__Tag {
        color: $red-60;
    }
    
}
