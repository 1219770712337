@import 'Styles/Dansskolanentre/includes';

/**
*
* Breadcrumbs
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Breadcrumbs.Base.module.scss';

.Breadcrumbs {
    $root: &;

    align-items: center;

    @include media(md) {
        margin-bottom: 10px;
    }

    &--Article {
        margin-bottom: 16px;
        padding-top: 8px;

        @include media(md) {
            margin-bottom: 29px;
            padding-top: 11px;
        }
    }

    &__Link {
        color: white;
        font-size: 1.1rem;
        text-transform: uppercase;

        #{$root}--Article & {
            color: $text-color;
        }
    }

    &__Page {
        color: white;
        font-size: 1.1rem;
        text-transform: uppercase;

        #{$root}--Article & {
            color: $text-color;
        }
    }

    &__Separator {
        display: inline-block;
        position: relative;
        margin: 0 8px;
        top: 1px;
        width: 5px;
        height: 9px;

        svg {
            width: 100%;
            height: 100%;

            * {
                fill: white;
            }
        }

        #{$root}--Article & {
            svg * {
                fill: $grey-80;
            }
        }
    }
}
