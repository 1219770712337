@import 'Styles/Base/includes';

/**
*
* GlobalSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.GlobalSearch {
    $root: &;

    &__Container {
        @include u-wrap(article);

        padding-top: 10px;
        padding-bottom: 60px;

        @include media(sm) {
            padding-top: 20px;
        }

        @include media(md) {
            padding-top: 40px;
            padding-bottom: 80px;
        }
    }

    &__Header {
        display: none;
    }

    &__Form {
        margin: 10px 0 30px;

        @include media(md) {
            margin-bottom: 50px;
        }
    }

    &__Title {
        margin: 16px 0;
        font-size: 1.4rem;
        line-height: 1.52;

        span {
            display: none;
            white-space: nowrap;
        }

        &--Keyword {
            :global(span.resultKeyword) {
                display: inline;
            }
        }

        @include media(sm) {
            margin: 24px 0;
        }
    }

    &__Tabs {
        position: relative;
        width: 100%;
        margin: 40px 0;
        border-bottom: 1px solid;

        &::after {
            content: '';
            width: 40px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__TabList {
        display: flex;
        position: relative;
        width: 100%;
        padding-right: 40px;
        overflow-x: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__Tab {
        flex: 0 0 auto;
        position: relative;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }

        &:hover:not(#{$root}__Tab--Active) {
            &::after {
                opacity: .5;
            }
        }

        &--Active {
            &::after {
                opacity: 1;
            }
        }
    }

    &__TabButton {
        padding: 8px 16px 18px;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        cursor: pointer;

        @include media(sm) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    &__List {
        display: flex;
        flex-direction: column;
        margin: 16px 0;

        @include media(sm) {
            margin: 24px 0;
        }

        #{$root}__NoResult + & {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__Button {
        align-self: center;
        margin-right: 12px;

        @include media(xs) {
            margin-top: 8px;
            margin-right: 0;
        }

        span.resultType {
            display: none;

            @include media(xs) {
                display: inline;
            }
        }
    }

    &__NoResult {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 20px;
        margin: 25px 0 30px 0;
        font-size: 1.4rem;
        text-align: center;

        @include media(md) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            min-height: 148px;
            font-size: 1.8rem;
            text-align: left;
            padding: 40px;
            margin-top: 15px;
        }
    }

    &__NoResultBorder {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__NoResultText {
        flex: 1 1 auto;
        position: relative;
        padding-bottom: 20px;

        @include media(md) {
            padding-bottom: 0;
            padding-right: 40px;
        }
    }

    &__NoResultButton {
        flex: 0 0 auto;
        position: relative;
        margin-bottom: 8px;
    }

    &__Error {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    &__ErrorMessage {
        position: relative;
        padding: 14px 20px;
        color: $error-color;
        font-size: 1.4rem;
        text-align: center;
        border: 1px solid $error-color;
        background: rgba($error-color, .04);
    }
}
