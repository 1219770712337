@import 'Styles/Pahlmans/includes';

/**
*
* SideBarLinkList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SideBarLinkList.Base.module.scss';

.SideBarLinkList {
    $root: &;

    &__List {
        margin-top: 10px;
    }

    &__Item {
        padding: 18px 18px 52px;
        background-color: white;
        margin-bottom: 24px;
        border-left: 4px solid $primary-color;

        svg {
            position: absolute;
            bottom: 22px;
            left: 18px;
            stroke: $primary-color;
            width: 20px;
            height: 14px;
        }

        &--External {
            padding: 18px;
            border: none;

            svg {
                width: 22px;
                height: 22px;
                top: 18px;
                fill: $primary-color;
                stroke: transparent;
            }
        }
    }

    &__Title {
        font-size: 1.8rem;
        font-weight: $medium;
        line-height: 1.5;
        color: $primary-color;

        #{$root}__Item--External & {
            font-family: $font-base;
            padding-left: 34px;
            font-size: 1.6rem;
            color: $text-color;
        }
    }

    &__Text {
        margin-top: 10px;
    }

    &__Tag {
        color: $primary-color;
        font-weight: $medium;
    }
}
