@import 'Styles/Dansskolanentre/includes';

/**
*
* ButtonLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ButtonLink {
    $root: &;

    @include u-button(solid);

    &--Border {
        @include u-button(border, false);
    }

    &--Arrow {
        &::after {
            content: '';
            width: 20px;
            height: 20px;
            background: url('#{$basepath}img/bg-icons/dansskolanentre/arrow--white.svg') no-repeat center center;
            background-size: contain;
        }
    }
}
