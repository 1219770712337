@import 'Styles/Medborgarskolan/includes';

/**
*
* ButtonLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ButtonLink {
    $root: &;

    @include u-button();

    &--Primary {
        @include u-button(primary, false);
    }

    &--Secondary {
        @include u-button(secondary, false);
    }

    &--Tertiary {
        @include u-button(tertiary, false);
    }

    &--Link {
        @include u-button(link, false);
    }

    &--Block {
        @include u-button(block, false);
    }

    &__Text {
        width: 100%;
        display: inline-block;

        #{$root}__Link--Overflow & {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
