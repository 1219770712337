@import 'Styles/Pahlmans/includes';

/**
*
* GlobalSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'GlobalSearch.Base.module.scss';

.GlobalSearch {
    $root: &;

    &__Form {
    }

    &__Title {
        font-family: $font-base;
    }

    &__Tabs {
        border-color: palette(black, 15);

        &::after {
            background: linear-gradient(90deg, transparent 0%, $body-bg 100%);
        }
    }

    &__Tab {
        &::after {
            height: 4px;
            background: $primary-color;
        }
    }

    &__TabButton {
        color: palette(black, 86);
        font-family: $font-heading;
        font-size: 1.8rem;
        font-weight: $medium;
    }

    &__Button {
        @include u-button(primary);

        margin-bottom: 26px;

        @include media(sm) {
            margin-bottom: 36px;
        }
    }

    &__List {
        margin: 0;
    }

    &__NoResult {
    }

    &__NoResultText {
        padding: 0;
        margin: 0 auto;
        max-width: 524px;
        color: palette(black, 86);
        font-size: 2.4rem;
        text-align: center;
        font-weight: $medium;
        font-family: $font-heading;

        em {
            font-weight: $black;
        }

        @include media(sm) {
            font-size: 3.2rem;
        }
    }

    &__NoResultButton {
        display: none;
    }

    &__Subjects {
        margin: 30px 0 20px;

        @include media(sm) {
            margin: 36px 0 30px;
        }
    }

    &__Selected {
        padding-top: 26px;
        border-top: 1px solid palette(black, 15);

        @include media(sm) {
            padding-top: 36px;
        }
    }
}
