@import 'Styles/Pahlmans/includes';

/**
*
* Breadcrumbs
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Breadcrumbs.Base.module.scss';

.Breadcrumbs {
    $root: &;

    align-items: center;
    justify-content: center;

    @include media(md) {
        margin-bottom: 30px;
    }

    &--Article {
        justify-content: flex-start;
        min-height: 53px;

        @include media(md) {
            min-height: 60px;
            margin-bottom: 20px;
            padding-top: 18px;
            border-bottom: solid 1px palette(black, 15);
        }
    }

    :global(.PageNews) & {
        @include media(md) {
            border-bottom: none;
            margin-bottom: 30px;
        }
    }

    &__Link {
        color: white;
        font-size: 1.2rem;
        font-family: $font-base;
        font-weight: $semibold;
        #{$root}--Article & {
            color: palette(black, 84);
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 0.9px;
        }

        @include media(md) {
            font-size: 1.4rem;

            #{$root}--Article & {
                color: $text-color;
                font-size: 1.2rem;
            }

        }
    }

    &__Page {
        color: white;
        font-size: 1.2rem;
        font-family: $font-base;
        font-weight: $semibold;
        #{$root}--Article & {
            color: palette(black, 84);
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 0.9px;
        }

        @include media(md) {
            font-size: 1.4rem;

            #{$root}--Article & {
                color: $text-color;
                font-size: 1.2rem;
            }

        }
    }

    &__Separator {
        padding: 0 5px;

        &:before {
            position: relative;
            top: 1px;
            content: '/';
            display: inline-block;
            color: $primary-color;
            font-weight: $bold;
        }

        #{$root}--Article &:before {
          background-image: url('#{$basepath}img/bg-icons/pahlmans/breadcrumbs.svg');
          width: 11px;
          height: 10px;
          background-repeat: no-repeat;
          background-position: center;
          content:' ';
        }

        svg {
            display: none;
        }

        #{$root}--Article & svg {
            display: none;
            * {
          //      fill: palette(black, 16);
            }

            @include media(md) {
                top: -1px;
            }
        }

    }
}
