@import 'Styles/Tillskararakademin/includes';

/**
*
* ButtonLink
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ButtonLink {
    $root: &;

    display: inline-block;

    &__Text {
        text-transform: uppercase;
        font-family: $font-base;
        font-weight: $bold;
        font-size: 1.8rem;
        padding-right: 14px;
    }

    &:after {
        content: '';
        width: 20px;
        height: 20px;
        background: url('#{$basepath}img/bg-icons/tillskararakademin/arrow.svg') no-repeat center center;
        background-size: contain;
    }
}
