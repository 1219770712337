@import 'Styles/Tillskararakademin/includes';

/**
*
* Hero
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Hero.Base.module.scss';

.Hero {
    $root: &;

    background-color: palette(black);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &::before {
        background: palette(black);

        #{$root}--NoImage & {
            display: none;
        }
    }

    &__Container {
        padding-top: 130px;
        padding-bottom: 130px;
        text-align: center;

        @include media(md) {
            min-height: 490px;
            padding-top: 160px;
            padding-bottom: 160px;
        }

        #{$root}--PageHome & {
            padding-top: 150px;
            padding-bottom: 160px;

            @include media(md) {
                padding-top: 160px;
                padding-bottom: 180px;
            }
        }
    }

    &__TextContainer {
        @include media(md) {
            padding: 0 60px;
        }
    }

    &__Label {
        color: palette(white);
        font-weight: $medium;
        line-height: 2.2;
    }

    &__Title {
        font-size: 4rem;
        font-weight: $bold;
        line-height: 1.1;
        color: palette(white);

        @include media(md) {
            font-size: 8rem;
        }
    }

    // &__Search {
    //     margin-top: 20px;
    //
    //     @include media(md) {
    //         margin-top: 40px;
    //     }
    //
    //     #{$root}--PageHome & {
    //         margin-top: 60px;
    //
    //         @include media(md) {
    //             margin-top: 60px;
    //         }
    //     }
    // }
}
