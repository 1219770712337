@import 'Styles/Base/includes';

/**
*
* HeroPage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.HeroPage {
    $root: &;

    position: relative;
    width: 100%;

    &__Background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            opacity: 0.3;

            #{$root}--NoImage & {
                display: none;
            }
        }
    }

    &__BreakoutBreadcrumbs {
        display: none;
    }

    &__Container {
        @include u-wrap(site);
        position: relative;
        min-height: 300px;
    }
}
