@import 'Styles/Medborgarskolan/includes';

/**
*
* Grid
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Grid.Base.module';

.Grid {
    $root: &;

    padding: 20px 0 32px;
    background: $blue-20;

    @include media(md) {
        padding: 32px 0 56px;
    }

    &--NoTopSpacing {
        padding-top: 0 !important;
    }

    &__Title {
        margin-bottom: 10px;
        font-size: 3.2rem;
        color: $blue;

        @include media(md) {
            margin-bottom: 30px;
            font-size: 4.8rem;
        }
    }

    &__Text {
        font-size: 2rem;
    }

    &__Grid {
        margin-left: -20px;
        margin-bottom: -20px;

        @include media(sm) {
            margin-bottom: -24px;
            margin-left: -24px;
        }
    }

    &__Item {
        margin-bottom: 20px;
        margin-left: 20px;
        width: calc(100% - 20px);

        @include media(sm) {
            margin-bottom: 24px;
            margin-left: 24px;
            width: calc(50% - 24px);
        }

        @include media(lg) {
            width: calc(#{percentage(1/3)} - 24px);
        }
    }

    &__Button {
        margin-top: 28px;
        text-align: center;
    }

    &__Link {
        @include u-button(primary);

        flex: 0 0 auto;
        display: inline-block;
        position: relative;
        z-index: 0;

        &::before {
            z-index: z(behind);
        }

        &:hover {
            color: white;

            &::before {
                opacity: 1;
            }
        }

        &::after {
            content: '';
            display: inline-block;
            width: 15px;
            height: 12px;
            margin-left: 10px;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--white.svg') no-repeat center center;
            background-size: contain;
            transition: background .3s ease-in-out;
        }
    }
}
